<template>
  <div class="carousel">
    <CustomerReview
      class="review"
      v-for="(review, i) in reviews" :key="i"
      :title="review.title"
      :description="review.description"
      :author="review.author"
      :photo="review.photo"
      :style="positionStyle(review.position)"
      :highlight="review.position === 0"
      @click="rotateToPosition(review.position)"
    />
  </div>

  <div v-if="mobile" class="nav">
    <ArrowIcon class="arrow left" @click="rotateToLeft()"/>
    <ArrowIcon class="arrow right" @click="rotateToRight()"/>
  </div>
</template>

<script setup>
import CustomerReview from "@/modules/MbaExclusive/Components/CustomerReview.vue";
import ArrowIcon from "app/assets/images/mba/icons/arrow.svg?component";
import { useShared } from "@/helpers/vueHelpers.js";
import { sleep } from "@/utils/generalUtils.js";

const { reviews } = defineProps({ reviews: Array });
const { mobile } = useShared();

const minPos = reviews.at(0).position;
const maxPos = reviews.at(-1).position;
const transitionDuration = 100;

function positionStyle(pos) {
  const translateX = 53 * pos;
  const scaleY = 1 - Math.abs(pos) * 0.05;
  return {
    zIndex: 100 - Math.abs(pos),
    position: pos === 0 ? 'static' : 'absolute',
    transform: `translateX(${translateX}%) scaleY(${scaleY})`,
    transitionDuration: `${transitionDuration}ms`,
  }
}

async function rotateToLeft(n = 1) {
  for (let i = 0; i < n; i++) {
    reviews.forEach(r => r.position = r.position < maxPos ? r.position + 1 : minPos);
    await sleep(transitionDuration);
  }
}

async function rotateToRight(n = 1) {
  for (let i = 0; i < n; i++) {
    reviews.forEach(r => r.position = r.position > minPos ? r.position - 1 : maxPos);
    await sleep(transitionDuration);
  }
}

function rotateToPosition(pos) {
  if (pos > 0) rotateToRight(pos);
  if (pos < 0) rotateToLeft(-pos);
}
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  align-items: flex-end;
  position: relative;

  .review {
    cursor: pointer;
    transform-origin: bottom center;
    transition-property: transform;
    transition-timing-function: linear;
  }
}

.nav {
  margin-top: 12px;
  display: flex;
  gap: 12px;
}

.arrow {
  width: 36px;
  color: $brand-primary;
  cursor: pointer;

  &.right {
    transform: rotate(180deg);
  }
}
</style>